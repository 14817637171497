import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import Productivity from "../components/Solutions/Productivity/Productivity";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/productivity.jpg"

const ProductivityPage = () => {
    return(
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Productivity - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="keywords" content="first-party"/>
                <meta name="description"
                      content="DrivosityEDGE offers a productivity solution for your food delivery business with in-depth reporting features. Our real-time GPS tracking measures driver efficiency, customer feedback, and driver engagement to optimize performance. Schedule a demo now."/>

                <meta property="og:title" content="Productivity - Drivosity"/>
                <meta property="og:description" content="DrivosityEDGE offers a productivity solution for your food delivery business with in-depth reporting features. Our real-time GPS tracking measures driver efficiency, customer feedback, and driver engagement to optimize performance. Schedule a demo now."/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Business professional using a digital interface with icons representing productivity, analytics, and efficiency optimization."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/productivity`}/>
            </Helmet>
            <Layout component={<Productivity/>}/>
        </>
    )
}

export default ProductivityPage;
